
.product-configuration-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 16px 16px;

  background-color: $color-gray97;
  border-radius: 8px;

  @media screen and (min-width: 1024px) {
    padding: 24px;
  }

  .product-configuration-label {
    line-height: 19px;
    letter-spacing: -0.32px;
  }

  .product-configuration-info-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 0 8px;

    @media screen and (min-width: 1024px) {
      flex-wrap: nowrap;
    }

    .product-image {
      height: 32px;
      // to fix width size of ImagePlaceholder component
      width: 32px;
      min-width: 32px;

      align-self: flex-start;

      > img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
      }
    }

    .product-info {
      flex: 1;

      display: inline-block;
      vertical-align: top;
      line-height: 22px;
      letter-spacing: -0.32px;

      .product-title,
      .product-price-container {
        display: inline;
      }

      @media screen and (min-width: 1024px) {
        .product-title,
        .product-price-container {
          display: block;
        }
      }

      .product-price-container {
        > .selling-price {
          &.-unavailable {
            color: $color-bnn;
          }
        }

        > .srp-price {
          margin-left: 8px;

          text-decoration: line-through;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }

    .product-action-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .btn {
        text-wrap: nowrap;
        width: 100%;
      }

      @media screen and (min-width: 1024px) {
        width: fit-content;

        .btn {
          width: fit-content;
        }
      }
    }
  }
}
